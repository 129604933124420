import { ButtonDemos, SliderDemos, TabsDemos, AccordionDemos, ThemeDemos, InputDemos } from '@k-link/demos';
import { Slider as SliderStylesApi, Button as ButtonStylesApi } from '@k-link/styles-api';
import * as React from 'react';
export default {
  ButtonDemos,
  SliderDemos,
  TabsDemos,
  AccordionDemos,
  ThemeDemos,
  InputDemos,
  SliderStylesApi,
  ButtonStylesApi,
  React
};